<template>
  <div>
    <b-btn
      v-if="edicao"
      v-b-tooltip.hover
      variant="none"
      title="Salvar"
      class="btn-outline-success btn-none rounded-pill icone-acao mr-50"
      @click="salvarItem"
    >
      <feather-icon icon="CheckIcon" />
    </b-btn>

    <!-- <b-btn
      v-if="edicao"
      v-b-tooltip.hover
      variant="none"
      title="Remover"
      class="btn-outline-danger btn-none rounded-pill icone-acao mr-50"
      @click="cancelarItem"
    >
      <feather-icon icon="XIcon" />
    </b-btn> -->

    <b-btn
      v-if="!edicao"
      v-b-tooltip.hover
      variant="none"
      title="Editar"
      class="btn btn-none rounded-pill icone-acao mr-50"
      @click="editarItem"
    >
      <feather-icon icon="EditIcon" />
    </b-btn>

    <b-btn
      v-if="!edicao"
      v-b-tooltip.hover
      variant="none"
      title="Remover"
      class="btn-outline-danger btn-none rounded-pill icone-acao mr-50"
      @click="removerItem"
    >
      <feather-icon icon="TrashIcon" />
    </b-btn>

    <!-- v-if="edicao" -->
    <b-btn
      v-if="ultimo"
      v-b-tooltip.hover
      variant="none"
      title="Adicionar"
      class="btn-outline-info btn-none rounded-pill icone-acao"
      @click="adicionarItem"
    >
      <feather-icon icon="PlusIcon" />
    </b-btn>
  </div>
</template>

<style lang="scss">
.icone-acao {
  padding: 0.4rem;
}

.tabela-repeticao th:last-child,
.tabela-repeticao td:last-child {
  max-width: 12rem;
  width: 13rem;
}
</style>

<script>
export default {
  name: 'AcoesLinha',
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    edicao: {
      type: Boolean,
      default: false,
    },
    ultimo: {
      type: Boolean,
      default: false,
    },
    validar: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      itemInicial: {},
    }
  },
  watch: {
    edicao(value, oldValue) {
      if (value !== oldValue && !value) {
        if (this.isLinhaVazia(this.item)) {
          this.removerItem()
        }
      }
    },
  },
  created() {
    this.itemInicial = { ...this.item }
  },
  methods: {
    adicionarItem() {
      this.salvarItem()
        .then(payload => {
          if (payload) {
            this.$emit('novo', { code: null, emEdicao: true })
          }
        })
        .catch(err => {
          throw err
        })
    },
    salvarItem() {
      return new Promise((resolve, reject) => {
        this.validar(this.item, this.index)
          .then(payload => {
            if (payload) {
              this.salvarItemValidado()
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(err => {
            this.salvarItemValidado()
            reject(err)
          })
      })
    },
    salvarItemValidado() {
      const item = { ...this.item }
      if (!this.item.code) {
        item.code = Date.now().toString(36) + Math.random().toString(36).substring(2)
      }
      item.emEdicao = false
      this.$emit('input', item, this.index)

      this.itemInicial = item
    },
    cancelarItem() {
      this.itemInicial.emEdicao = false
      this.$emit('cancelar', this.itemInicial, this.index)
    },
    editarItem() {
      this.$emit('editar', this.item, this.index)
    },
    removerItem() {
      this.$emit('remover', this.index)
    },
    isLinhaVazia(item) {
      let vazio = true
      Object.entries(item).forEach(([key, value]) => {
        if (key !== 'emEdicao' || key !== 'erro' || key !== 'code') {
          vazio = vazio && !value
        }
      })
      return vazio
    },
  },
}
</script>
